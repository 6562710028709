<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Ofertas</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Ofertas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <h5>Ofertas</h5>
              </div>
              <div class="card-body p-0">
                <table
                  id="ofertas"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        Fecha Inicial
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_inicial"
                          @change="getIndex()"
                        />
                      </th>
                      <th>
                        Fecha Final
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_final"
                          @change="getIndex()"
                        />
                      </th>
                      <th class="text-center">Observación</th>
                      <th class="text-center">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(oferta, index) in ofertas.data" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">
                        {{ oferta.fecha_inicial }}
                      </td>
                      <td class="text-center">
                        {{ oferta.fecha_final }}
                      </td>
                      <td>{{ oferta.observaciones }}</td>
                      <td style="width: 50px" class="text-right">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="
                              $store.getters.can('hidrocarburos.ofertas.show')
                            "
                            @click="
                              edit(oferta.id)
                            "
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="ofertas.total">
                  <p>
                    Mostrando del <b>{{ ofertas.from }}</b> al
                    <b>{{ ofertas.to }}</b> de un total:
                    <b>{{ ofertas.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="ofertas"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";

export default {
  name: "OfertaIndex",
  components: {
    pagination,
    Loading,
  },
  data() {
    return {
      ofertas: {},
      filtros: {
        fecha_inicial: null,
        fecha_final: null,
        userEmpresa: null,
      },
      cargando: false,
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      this.getUserEmpresa();
      axios
        .get("/api/hidrocarburos/ofertas?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.ofertas = response.data;
          this.cargando = false;
        });
    },

    // Se busca las empresas realcionadas al usuario
    getUserEmpresa() {
      this.filtros.userEmpresa = [];
      for (const iterator of this.$store.getters.getUser.empresas) {
        this.filtros.userEmpresa.push(iterator.id);
      }
    },

    edit(planeacion_id) {
      return this.$router.push({
        name: "/Hidrocarburos/OfertaForm",
        params: { planeacion_id },
      });
    },
  },
  mounted() {
    this.getIndex();
  },
};
</script>
